@font-face {
  font-family: primaryLight;
  src: url('assets/fonts/OpenSans_Condensed-Light.ttf');
}

@font-face {
  font-family: primaryRegular;
  src: url('assets/fonts/OpenSans_Condensed-Regular.ttf');
}

@font-face {
  font-family: primaryItalic;
  src: url('assets/fonts/OpenSans_Condensed-Italic.ttf');
}

@font-face {
  font-family: primarySemiBoldItalic;
  src: url('assets/fonts/OpenSans_Condensed-SemiBoldItalic.ttf');
}

@font-face {
  font-family: primarySemiBold;
  src: url('assets/fonts/OpenSans_Condensed-SemiBold.ttf');
}

@font-face {
  font-family: primaryBold;
  src: url('assets/fonts/OpenSans_Condensed-Bold.ttf');
}

@font-face {
  font-family: primaryExtraBold;
  src: url('assets/fonts/OpenSans_Condensed-ExtraBold.ttf');
}
